<template>
    <b-overlay :show="loading">

        <b-card class="mb-2">
            <h4 class="mb-2">Informasi Pembelian</h4>
            <b-row class="mb-4">
                <b-col sm="12" md="6" class="mb-2">
                    <label for="">Pilih Supplier</label>
                    <v-select :options="suppliers" label="text" v-model="supplier"></v-select>
                </b-col>
                <b-col cols="6" class="mb-2">
                    <label for="" v-if="isOwner">Pilih Jenis Pembelian</label>
                    <v-select v-if="isOwner" :options="[{value: 1, text: 'Rokok'}, {value: 2, text: 'Non-Rokok'}]" label="text" :reduce="option => option.value" v-model="form.jenis">
                    </v-select>
                </b-col>
                <b-col cols="6" class="mb-2">
                    <label for="">Tanggal Pembelian</label>
                    <b-form-input v-model="form.tanggal" type="date" class="w-full" />
                </b-col>
                <b-col cols="6" class="mb-2">
                    <label for="">Tanggal Jatuh Tempo (opsional)</label>
                    <b-form-input v-model="form.jatuh_tempo" type="date" class="w-full" />
                </b-col>
                <b-col cols="12">
                    <label for="">Keterangan (opsional)</label>
                    <b-form-textarea v-model="form.keterangan"></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card>
            <h4 class="mb-2">Rincian Barang <span v-if="selectedBarangs.length > 0">({{selectedBarangs.length}})</span></h4>
            <b-button variant="primary" class="mb-3" @click.prevent="chooseBarang">Pilih Barang</b-button>
            <b-table v-if="selectedBarangs.length > 0" responsive :fields="fields" :items="selectedBarangs">
                <template #table-colgroup="{fields}">
                    <col
                        v-for="field in fields"
                        :key="field.key"
                        :style="{width: field.key == 'no' ? '20px' : ''}"
                    />
                </template>
                <template #cell(no)="{index}">
                    {{(1 + index)}}
                </template>
                <template #cell(no_container)="{item}">
                    <b-form-input v-model="item.no_container" placeholder="Ex: MRT123213"></b-form-input>
                </template>
                <template #cell(no_seal)="{item}">
                    <b-form-input v-model="item.no_seal" placeholder="Ex: GR123"></b-form-input>
                </template>
                <template #cell(harga_beli)="{item}">
                    <div>{{formatRupiah(item.harga_beli)}}</div>
                    <strong class="text-danger" v-if="item.diskon && item.diskon > 0">
                        diskon {{ formatRupiah(item.diskon) }}
                    </strong>
                </template>
                <template #cell(diskon)="{item}">
                    {{formatRupiah(item.diskon)}}
                </template>
                <template #cell(qty)="{item}">
                    {{item.qty}} /{{ item.satuan ? item.satuan.satuan : '' }}
                </template>
                <template #cell(total)="{item}">
                    {{ formatRupiah(parseInt(item.harga_beli) * parseInt(item.qty)) }}
                </template>
                <template #cell(action)="{item, index}">
                    <feather-icon icon="MinusCircleIcon" size="24" class="text-danger cursor-pointer" @click="removeBarang(item, index)"></feather-icon>
                </template>
            </b-table>
            <div v-else class="alert alert-danger p-2">
                <strong>Harap</strong> pilih barang rincian
            </div>
            <div class="d-flex justify-content-end align-items-center mt-3 px-2">
                <h4>Total Pembelian: </h4>
                <h2 class="ml-2">Rp {{ formatRupiah(totalPembelian) }}</h2>
            </div>
        </b-card>
        <barang-modal :barangs="barangs" @submit="onChooseBarang"></barang-modal>
        <div class="d-flex justify-content-end">
            <b-button :disabled="!isValidForm" @click.prevent="submit" :variant="!isValidForm ? 'secondary' : 'primary'">Simpan</b-button>
        </div>
    </b-overlay>
</template>
<script>
import BarangModal from './components/Modal.vue'
import vSelect from 'vue-select'
import {BCard, BButton, BRow, BOverlay, BTable, BCol, BFormInput, BFormSelect, BFormTextarea} from 'bootstrap-vue'
export default {
    components: {
        BarangModal, BCard, BOverlay, BButton, BTable, BRow, BCol, BFormInput, BFormSelect, BFormTextarea,
        vSelect
    },
    data:() => ({
        supplier: null, // for selected supplier
        fields: [
            { key: 'no_container', label: 'No. Container' },
            { key: 'no_seal', label: 'No. Seal' },
            { key: 'nama', label: 'Nama Barang' },
            { key: 'harga_beli', label: 'Harga Beli' },
            { key: 'qty', label: 'Qty' },
            { key: 'total', label: 'Total' },
            { key: 'action', label: '' }
        ],
        selectedBarangs: [],
        form: {
            tanggal: null,
            jenis: 2,
            kategori: 0,
            id_kas: null,
            id_supplier: null,
            keterangan: null,
            jatuh_tempo: null
        },
        suppliers: [],
        barangs: [],
        loading: false,
        hasPermissionApprove: false
    }),
    computed: {
        isKas() {
            return this.akun && this.akun.kategori && this.akun.kategori.kategori == 'Kas & Bank'
        },
        totalPembelian() {
            return this.selectedBarangs.reduce((total, barang) => total += ((barang.harga_beli * barang.qty) - barang.diskon),0)
        },
        isValidForm() {
            if(!this.form.tanggal || !this.supplier || this.selectedBarangs.length < 1) {
                return false
            }

            return true
        },
        updateMode() {
            if(this.$route.params.id) {
                return true
            }
            return false
        }
    },
    methods: {
        async checkPermission() {
            const menu = await this.currentMenu(this.$route.meta.parent)
            const params = {
                level_id: this.user.level.id
            }
            if(menu) {
                params.menu_id = menu.id
            }
            this.listStatus = await this.$store.dispatch('statusrole/getData', params)

            // data_status => 1 approve gm, data_status => 2 pay finance
            const hasPermissionApprove = this.listStatus.some(status => status.data_status == 1)
            
            this.hasPermissionApprove = hasPermissionApprove
        },
        async submit() {
            if(!this.isValidForm) {
                this.displayError({
                    message: 'Harap lengkapi form pembelian!'
                })
                return false
            }

            const payload = {
                jenis: this.isGM ? 2 : this.form.jenis,
                kategori: this.form.kategori,
                tanggal: this.form.tanggal,
                id_supplier: this.supplier.value,
                item: []
            }

            if(this.form.keterangan) {
                payload.keterangan = this.form.keterangan
            }
            if(this.form.jatuh_tempo) {
                payload.jatuh_tempo = this.form.jatuh_tempo
            }
            if(this.updateMode) {
                payload.id = this.$route.params.id
            }
            if(this.hasPermissionApprove) {
                payload.izin = 1
            }

            // make payload item rincian barang
            this.selectedBarangs.map(barang => {
                const payloadBarang = {
                    id_barang: barang.id,
                    harga_beli: barang.harga_beli,
                    qty: parseInt(barang.qty)
                }
                if(barang.rincian_id) {
                    payloadBarang.id = barang.rincian_id
                }
                if(barang.no_container) {
                    payloadBarang.no_container = barang.no_container
                }
                if(barang.no_seal) {
                    payloadBarang.no_seal = barang.no_seal
                }
                const diskon = parseInt(barang.diskon)
                if( diskon > 0) {
                    payloadBarang.diskon = diskon
                }
                if(this.updateMode) {
                    payloadBarang.id_pembelian = this.$route.params.id
                }

                payload.item.push(payloadBarang)
            })

            // check jika ada harga dasar baru, apakah ingin update harga dasar baru
            try {
                this.loading = true
                await this.$store.dispatch('pembelian/save', [payload])
                if(this.updateMode) {
                    await this.$store.dispatch('pembelian-rincian/save', payload.item)
                }

                this.loading = false
                this.displaySuccess({
                    message: 'Pembelian berhasil'
                })
                const thereChangePrice = this.selectedBarangs.some(barang => barang.isChangePrice == true)

                if(thereChangePrice) {
                    this.updateBasePrice()
                }
                else {
                    setTimeout(() => this.$router.push('/pembelian'), 500)
                }
            }
            catch(e) {
                this.loading = false
                this.displayError(e)
                return false
            }

        },
        updateBasePrice() {
            this.$swal({
                title: 'Update harga dasar?',
                text: 'Harga dasar barang yang anda pilih akan diupdate!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Batal',
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                }
            })
            .then(res => {
                if(res.value) {
                    const barangs = this.selectedBarangs.filter(barang => barang.isChangePrice)
                    const payloads = barangs.map(barang => ({
                        id: barang.id,
                        kode: barang.kode,
                        nama: barang.nama,
                        varian: barang.varian,
                        jenis: barang.jenis,
                        id_kategori: barang.kategori ? barang.kategori.id : null,
                        id_satuan: barang.satuan ? barang.satuan.id : null,
                        harga_dasar: barang.harga_dasar_baru
                    }))

                    this.loading = true
                    this.$store.dispatch('barang/save', payloads)
                    .then(() => {
                        this.loading = false
                        setTimeout(() => this.$router.push('/pembelian'), 500)
                    })
                    .catch(e => {
                        this.loading = false
                        this.displayError(e)
                        return false
                    })
                }
                else {
                    setTimeout(() => this.$router.push('/pembelian'), 500)
                }
            })
        },
        removeBarang(item, index) {
            if(!item.rincian_id) {
                this.selectedBarangs.splice(index, 1)
            }
            else {
                // remove via API
                const payload = {
                    id: item.rincian_id,
                    fungsi: 2
                }
                this.$store.dispatch('pembelian-rincian/save', [payload])
                .then(() => this.selectedBarangs.splice(index, 1))
                .catch(e => {
                    this.displayError(e)
                    return false
                })
            }
        },
        onChooseBarang(selectedBarangs) {
            selectedBarangs.map(barang => {
                barang.no_container = null
                barang.no_seal = null
                this.selectedBarangs.push(barang)
            })
            this.$bvModal.hide('barang-modal')
        },
        chooseBarang() {
            this.$bvModal.show('barang-modal')
        },
        async getBarang() {
            // const jenis = this.isOwner ? 1 : 2
            const params = {  }
            if(this.isGM) params.jenis = 2
            this.barangs = await this.$store.dispatch('barang/getData', params)
            this.barangs.map(barang => {
                barang.qty = 0
                barang.diskon = 0
                barang.harga_beli = 0
                barang.isChangePrice = false
                barang.harga_dasar_baru = 0 // bila ingin merubha harga dasar
            })
        },
        async getSupplier() {
            const params = this.isGM ? {jenis: 2} : {}
            const suppliers = await this.$store.dispatch('supplier/getData', params)
            suppliers.map(supplier => {
                this.suppliers.push({
                    text: supplier.instansi,
                    value: supplier.id
                })
            })
        }
    },
    async created() {
        this.getBarang()
        this.form.jenis = 2
        await this.checkPermission()
        if(!this.hasPermissionApprove) {
            this.$router.push('/pembelian')
        }
        await this.getSupplier()
        
        this.form.tanggal = this.getCurrentDate()
        if(this.updateMode) {
            const pembelians = this.$store.state.pembelian.datas
            const pembelian = pembelians.find(item => item.id == this.$route.params.id)
            if(!pembelian) {
                this.$router.push('/pembelian')
            }

            if(this.isGM && pembelian.jenis == 1) {
                this.$router.push('/pembelian')
            }

            this.form.tanggal = pembelian.tanggal
            this.form.jatuh_tempo = pembelian.jatuh_tempo
            this.form.jenis = pembelian.jenis
            this.form.keterangan = pembelian.keterangan
            this.form.id_supplier = pembelian.id_supplier
            this.supplier = this.suppliers.find(sp => sp.value == pembelian.id_supplier)
            pembelian.rincian.map(rincian => {
                if(rincian.barang) {
                    const {barang} = rincian
                    const item = {
                        rincian_id: rincian.id,
                        id: barang.id,
                        nama: barang.nama,
                        satuan: barang.satuan,
                        qty: rincian.qty,
                        diskon: rincian.diskon,
                        harga_beli: rincian.harga_beli
                    }
                    this.selectedBarangs.push(item)
                }
            })
        }
    }
}
</script>