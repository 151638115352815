<template>
    <b-modal id="barang-modal" size="xl" :no-close-on-backdrop="true" :no-close-on-esc="true">
        <header class="mb-1">
            <h5>Barang dipilih ({{ selectedBarangs.length }}).</h5>
            <b-form-group label="Filter">
                <b-input-group size="sm">
                    <b-form-input
                        id="filterInput"
                        v-model="filter"
                        type="search"
                        placeholder="Type to Search"
                    />
                    <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
        </header>
        <b-table :fields="fields" :filter="filter" :items="localBarangs" responsive>
            <template #head(checkbox)>
                <b-form-checkbox v-model="allChecked"></b-form-checkbox>
            </template>
            <template #cell(checkbox)="{item}">
                <b-form-checkbox v-model="selectedBarangs" :value="item"></b-form-checkbox>
            </template>
            <template #cell(harga_dasar)="{item}">
                <strong>{{ formatRupiah(item.harga_dasar) }}</strong>
            </template>
            <template #cell(harga_dasar_baru)="{item, index}">
                <b-form-input v-if="item.isChangePrice" @keyup="doFormatRupiahHDB(index)" v-model="item.harga_dasar_baru"></b-form-input>
                <strong v-else>{{ item.harga_dasar }}</strong>
            </template>
            <template #cell(harga_beli)="{item, index}">
                <b-form-input @keyup="doFormatRupiahHB(index)" v-model="item.harga_beli"></b-form-input>
            </template>
            <template #cell(diskon)="{item, index}">
                <b-form-input @keyup="doFormatRupiahDiskon(index)" v-model="item.diskon"></b-form-input>
            </template>
            <template #cell(qty)="{item}">
                <b-form-input type="number" v-model="item.qty"></b-form-input>
            </template>
            <template #cell(satuan)="{item}">
                {{ item.satuan ? item.satuan.satuan : '' }}
            </template>
            <template #cell(isChangePrice)="{item, index}">
                <b-form-checkbox v-model="item.isChangePrice" @change="onChangePrice(index)"></b-form-checkbox>
            </template>
        </b-table>
        <template #modal-footer>
            <b-button @click.prevent="submit" variant="primary">Pilih Barang</b-button>
        </template>
    </b-modal>    
</template>
<script>
import {BModal, BFormGroup, BInputGroup, BFormCheckbox, BFormInput, BInputGroupAppend, BButton, BTable} from 'bootstrap-vue'
export default {
    props: ['barangs'],
    components: {
        BModal, BFormGroup, BInputGroup, BFormCheckbox, BFormInput, BInputGroupAppend, BButton, BTable
    },
    data: () => ({
        allChecked: false,
        selectedBarangs: [],
        filter: null,
        fields: [
            { key: 'checkbox', label: '#' },
            { key: 'nama', label: 'Nama Barang' },
            { key: 'harga_dasar', label: 'Harga Dasar' },
            { key: 'harga_beli', label: 'Harga Beli Supplier' },
            { key: 'isChangePrice', label: 'Ubah Harga Dasar' },
            { key: 'diskon', label: 'Diskon' },
            { key: 'qty', label: 'Qty' },
            { key: 'satuan', label: 'Satuan' },
        ],
        localBarangs: []
    }),
    methods: {
        doFormatRupiahHB(index) {
            const barangs = Object.assign([], this.localBarangs)
            barangs[index].harga_beli = this.formatRupiah(barangs[index].harga_beli)
            // update reactivity
            this.localBarangs = barangs
        },
        doFormatRupiahHDB(index) {
            const barangs = Object.assign([], this.localBarangs)
            barangs[index].harga_dasar_baru = this.formatRupiah(barangs[index].harga_dasar_baru)
            // update reactivity
            this.localBarangs = barangs
        },
        doFormatRupiahDiskon(index) {
            const barangs = Object.assign([], this.localBarangs)
            barangs[index].diskon = this.formatRupiah(barangs[index].diskon)
            // update reactivity
            this.localBarangs = barangs
        },
        onChangePrice(index) {
            const barangs = Object.assign([], this.localBarangs)
            const isThereChange = this.localBarangs.some(barang => barang.isChangePrice == true)

            if(isThereChange) {
                this.fields = [
                    { key: 'checkbox', label: '#' },
                    { key: 'nama', label: 'Nama Barang' },
                    { key: 'harga_dasar', label: 'Harga Dasar' },
                    { key: 'harga_beli', label: 'Harga Beli Supplier' },
                    { key: 'isChangePrice', label: 'Ubah Harga Dasar' },
                    { key: 'harga_dasar_baru', label: 'Harga Baru' },
                    { key: 'diskon', label: 'Diskon' },
                    { key: 'qty', label: 'Qty' },
                    { key: 'satuan', label: 'Satuan' },
                ]
                
                setTimeout(() => {
                    this.localBarangs = barangs
                }, 500)
            }
            else {
                this.fields = [
                    { key: 'checkbox', label: '#' },
                    { key: 'nama', label: 'Nama Barang' },
                    { key: 'harga_dasar', label: 'Harga Dasar' },
                    { key: 'harga_beli', label: 'Harga Beli Supplier' },
                    { key: 'isChangePrice', label: 'Ubah Harga Dasar' },
                    { key: 'diskon', label: 'Diskon' },
                    { key: 'qty', label: 'Qty' },
                    { key: 'satuan', label: 'Satuan' },
                ]

                setTimeout(() => {
                    this.localBarangs = barangs
                }, 500)
            }
        },
        submit() {
            if(this.selectedBarangs.length < 1) {
                this.displayError({
                    message: 'Harap pilih barang!'
                })
                return false
            }

            const thereZeroQty = this.selectedBarangs.some(barang => parseInt(barang.qty) < 1)
            if(thereZeroQty) {
                this.displayError({
                    message: 'Kuantitas barang yang dipilih harus melebihi 0!'
                })
                return false
            }

            this.selectedBarangs.map(barang => {
                barang.harga_beli = this.unFormatRupiah(barang.harga_beli)
                barang.harga_dasar_baru = this.unFormatRupiah(barang.harga_dasar_baru)
                barang.diskon = this.unFormatRupiah(barang.diskon)
            })
            
            this.$emit('submit', this.selectedBarangs)
        }
    },
    watch: {
        allChecked(val) {
            if(val) {
                this.selectedBarangs = this.barangs
            }
            else {
                this.selectedBarangs = []
            }
        },
        barangs(val) {
            if(val) {
                this.localBarangs = val
            }
        }
    }
}
</script>